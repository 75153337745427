<template>
  <div class="ueditor">
    <vue-ueditor-wrap
      ref="ueditor"
      v-model="currentVal"
      :config="editorConfig"
      @ready="ready"
      @before-init="beforeInit"
    />
    <ueditor-upload-image
      ref="uploadImage"
      :view-image="route"
      @successUpload="loadingJPGSuccessed"
      @onImageHtml="onImageHtml"
    />
  </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap' // ES6 Module
export default {
  components: {
    VueUeditorWrap
  },
  props: {
    initialFrameHeight: {
      type: String,
      default: ''
    },
    initialFrameWidth: {
      type: String,
      default: ''
    },
    ueditorVal: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentVal: '',
      route: '',
      editorConfig: {
        // 访问 UEditor 静态资源的根路径，可参考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
        UEDITOR_HOME_URL: '/UEditor/',
        // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: '',
        wordCount: false,
        elementPathEnabled: false,
        initialFrameWidth: this.initialFrameWidth,
        initialFrameHeight: this.initialFrameHeight,
        zIndex: 2000
      }
    }
  },
  watch: {
    currentVal(newVal, oldVal) {
      this.$emit('ueditorMsg', newVal)
    },
    ueditorVal: function(newVal, oldVal) {
      this.currentVal = newVal
    }

  },

  methods: {
    styleChange() {

    },
    loadingJPGSuccessed(res) {
      this.route = res
    },
    ready(editorInstance) {
      // 获取实列
      console.log(`编辑器实例${editorInstance.key}: `, editorInstance)
      this.editorHandler = editorInstance
    },
    beforeInit() {
      window.UE.registerUI('button', (editor, uiName) => {
        // 创建一个 button
        const btn = new window.UE.ui.Button({
          name: uiName,
          title: '上传图片',
          cssRules:
            "background-image: url('https://dacai-static-1304549078.cos.ap-shanghai.myqcloud.com/78f784218083487ddae2846960fe8fc818c2d8fa') !important;background-size: cover",
          onclick: () => {
            console.log('上传图片')
            this.$refs.uploadImage.open()
          }
        })
        return btn
      })
    },
    onImageHtml() {
      const imageHtml = '<p><img src="' + this.route + '"/></p>'
      this.editorHandler.execCommand('inserthtml', imageHtml)
    }
  }
}
</script>

<style lang="scss">
.ueditor {
  display: block;
  width: 100%;
  line-height: 0;

}
  ::v-deep .edui-dialog{
    z-index: 9999;
  }
</style>
